import React from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { LinkButton } from '../../components/LinkButton';
import { formatFromDate, formatToDate } from '../../utils/date';
import { formatPercentage } from '../../utils/valueHelpers';

function GanttTitleComponent(props: {
  title: string;
  onTime: number | undefined;
  onTimeDeliverable: number | undefined;
  completion: number | undefined;
  from: Date | null;
  to: Date | null;
  children: React.ReactNode;
}) {
  return (
    <Stack direction="column" gap={1} marginBottom={1}>
      <Stack direction="row" alignItems="center">
        <Typography variant="h5" lineHeight="1.8" sx={{ marginRight: 2 }}>
          {props.title}
        </Typography>
        <Paper variant="outlined" sx={{ paddingX: 1.5, paddingY: 1, width: 500 }}>
          <Stack direction="row" justifyContent="flex-start" alignItems="baseline" gap={2} flex={1}>
            <Typography component="div" typography="body1" fontWeight="bold" whiteSpace="nowrap">
              {'On time:'}
              <Typography
                component="span"
                typography="body1"
                fontWeight="bold"
                color={props.onTime === 1 || props.onTime === undefined ? undefined : 'error.main'}
                sx={{ marginLeft: 1 }}
              >
                {props.onTime === undefined ? '—' : formatPercentage(props.onTime)}
              </Typography>
            </Typography>
            <Divider orientation="vertical" flexItem />
            <Typography component="div" typography="body1" fontWeight="bold">
              Completion:
              <Typography
                component="span"
                typography="body1"
                fontWeight="bold"
                color={props.completion === 1 || props.completion === undefined ? undefined : 'error.main'}
                sx={{ marginLeft: 1 }}
              >
                {props.completion === undefined ? '—' : formatPercentage(props.completion)}
              </Typography>
            </Typography>
            <Divider orientation="vertical" flexItem />
            <Typography component="div" typography="body1" fontWeight="bold">
              On time deliverable:
              <Typography
                component="span"
                typography="body1"
                fontWeight="bold"
                color={
                  props.onTimeDeliverable === 1 || props.onTimeDeliverable === undefined ? undefined : 'error.main'
                }
                sx={{ marginLeft: 1 }}
              >
                {props.onTimeDeliverable === undefined ? '—' : formatPercentage(props.onTimeDeliverable)}
              </Typography>
            </Typography>
          </Stack>
        </Paper>
        <Box sx={{ marginRight: { xs: 0, md: 'auto' } }} />
        <LinkButton to={`/overview?from=${formatFromDate(props.from)}&to=${formatToDate(props.to)}`} sx={{ margin: 0 }}>
          {'Overview'}
        </LinkButton>
      </Stack>
      {props.children}
    </Stack>
  );
}

export const GanttTitle = React.memo(GanttTitleComponent);
