import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorAlert } from '@top-solution/microtecnica-mui';
import { useAuth } from '@top-solution/microtecnica-utils';
import { add, isAfter } from 'date-fns';
import LoadingButton from '@mui/lab/LoadingButton';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {
  EditingTask,
  TaskCreateRequest,
  TaskConfigurationForm,
  TaskConfigurationFormSchema,
  TaskSchedule,
  TimeScheduleForm,
} from '../../../../../entities/Task';
import { UserRoleName } from '../../../../../entities/User';
import {
  useCreateTaskMutation,
  useReadCompletedTaskListQuery,
  useUpdateTaskMutation,
} from '../../../../../services/taskApi';
import { TaskConfigurationFormContent } from './TaskConfigurationFormContent';

type TaskConfigurationStepProps = Omit<DialogProps, 'onClose'> & {
  onClose: (value?: TaskConfigurationForm) => void;
  dateSchedule: TaskSchedule;
  timeSchedule: TimeScheduleForm;
  onBack: () => void;
  task?: EditingTask;
  dialogActions: React.ReactNode;
};

export function TaskConfigurationStep(props: TaskConfigurationStepProps): JSX.Element {
  const { onClose, dateSchedule, timeSchedule, onBack, task, dialogActions } = props;
  const { hasRole, username } = useAuth();

  const initialValues = useMemo(() => {
    if (!task) {
      return {
        isMachineTask: false,
        isDeliverable: false,
        duration: 0,
      };
    }
    return {
      ...task,
      categoryId: task.categoryId,
      backupUsername: task.backup,
      reviewerUsername: task.reviewer,
      ownerUsername: task.owner,
      isMachineTask: Boolean(true),
      duration: task.duration ?? 0,
    };
  }, [task]);

  const form = useForm<TaskConfigurationForm>({
    defaultValues: {
      ...{
        ownerUsername: hasRole(UserRoleName.USER) ? username ?? undefined : undefined,
      },
      ...initialValues,
      confirmUserChange: true,
    },
    resolver: zodResolver(TaskConfigurationFormSchema),
  });

  const { handleSubmit, control, watch, setValue } = form;

  const [create, createStatus] = useCreateTaskMutation();
  const [update, updateStatus] = useUpdateTaskMutation();

  const isLoading = createStatus.isLoading || updateStatus.isLoading;
  const error = createStatus.error ?? updateStatus.error;
  const readCompletedTaskListQuery = useReadCompletedTaskListQuery();

  const onSubmit = async (data: TaskConfigurationForm) => {
    let updatedDate = new Date();
    if (readCompletedTaskListQuery?.data) {
      for (const completedTask of readCompletedTaskListQuery.data) {
        if (completedTask.taskId === task?.id) {
          if (completedTask.scheduledDate && isAfter(new Date(completedTask.scheduledDate), updatedDate)) {
            updatedDate = add(new Date(completedTask.scheduledDate), { hours: 1 });
          }
        }
      }
    }

    const completeData: TaskCreateRequest = {
      ...data,
      processId: data.processId,
      subprocessId: data.subprocessId,
      areaId: data.areaId,
      departmentId: data.departmentId,
      scenarioId: data.scenarioId,
      categoryId: data.categoryId,
      schedule: dateSchedule,
      ...timeSchedule,
    };

    if (isAfter(updatedDate, new Date())) {
      completeData.updatedDate = updatedDate.toISOString();
    }

    if (task?.id) {
      await update({
        ...completeData,
        id: task.id,
      }).unwrap();
    } else {
      await create(completeData).unwrap();
    }

    onClose(data);
  };

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <TaskConfigurationFormContent control={control} watch={watch} setValue={setValue} task={task} />
          {error && <ErrorAlert error={error} />}
        </DialogContent>
        <DialogActions>
          {dialogActions}
          <Box sx={{ flex: 1, height: 1 }} />
          <Button color="inherit" variant="outlined" onClick={() => onBack()}>
            Back
          </Button>
          <LoadingButton type="submit" color="primary" variant="contained" loading={isLoading}>
            {task?.id ? 'Update Task' : 'Create Task'}
          </LoadingButton>
        </DialogActions>
      </form>
    </FormProvider>
  );
}
