import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorAlert } from '@top-solution/microtecnica-mui';
import { useAuth } from '@top-solution/microtecnica-utils';
import { isPast } from 'date-fns';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import { darken } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { DefinitionList } from '../../components/DefinitionList';
import { EscapeSelect } from '../../components/Form/EscapeSelect';
import { TurnbackSelect } from '../../components/Form/TurnbackSelect';
import { ScenarioLabel } from '../../components/ScenarioLabel';
import {
  CompletedTaskForm,
  GanttTask,
  TaskStatus,
  refineCompletedTaskFormSchema,
  taskStatusLabels,
} from '../../entities/Task';
import { useDepartmentsById } from '../../hooks/useDepartmentsById';
import { useProcessesById } from '../../hooks/useProcessesById';
import { useScenariosById } from '../../hooks/useScenariosById';
import { useSubprocessesById } from '../../hooks/useSubprocessesById';
import { useUsersByUsername } from '../../hooks/useUsersByUsername';
import {
  useCreateCompletedTaskMutation,
  useUpdateCompletedTaskMutation,
  useDeleteCompletedTaskMutation,
} from '../../services/taskApi';
import { formatDate, formatDateHour, formatHour, parseISODate } from '../../utils/date';
import { printUserName } from '../../utils/users';

function StatusButton<T>({
  value,
  status,
  color,
  label,
  disabled,
  sx,
}: {
  value: T;
  status: T;
  color: 'success' | 'error' | 'warning' | 'info' | 'default';
  label: string;
  disabled?: boolean;
  sx?: ButtonProps['sx'];
}) {
  const button = (
    <Box component="span" sx={{ flex: 1 }}>
      <FormControlLabel
        value={value}
        control={<Radio color={color} size="small" disabled={disabled} />}
        label={label}
        sx={{
          pointerEvents: disabled ? 'none' : undefined,
          border: '2px solid',
          borderColor: disabled ? 'grey.200' : status === value ? `${color}.light` : 'grey.300',
          color: (theme) =>
            disabled
              ? 'grey.400'
              : status === value
                ? color === 'default'
                  ? 'inherit'
                  : darken(theme.palette[color].main, 0.4)
                : theme.palette.text.secondary,
          borderRadius: 1,
          margin: 0,
          display: 'flex',
          paddingRight: '38px',
          '.MuiFormControlLabel-label': {
            flex: 1,
            textAlign: 'center',
            textTransform: 'uppercase',
            fontWeight: 500,
          },
          ...sx,
        }}
      />
    </Box>
  );

  if (disabled) {
    return <Tooltip title="The task due date is in the past">{button}</Tooltip>;
  }

  return button;
}

type GanttTaskDetailsDialogProps = Omit<DialogProps, 'onClose'> & {
  ganttTask: GanttTask;
  onClose: (GanttTaskForm?: GanttTask) => void;
};

export function GanttTaskDetailsDialog(props: GanttTaskDetailsDialogProps): JSX.Element {
  const { ganttTask, onClose, ...dialogProps } = props;

  const auth = useAuth();
  const [createCompletedTask, createCompletedTaskRequest] = useCreateCompletedTaskMutation();
  const [updateCompletedTask, updateCompletedTaskRequest] = useUpdateCompletedTaskMutation();
  const [deleteCompletedTask, deleteCompletedTaskRequest] = useDeleteCompletedTaskMutation();

  const [confirmChecked, setConfirmChecked] = useState(false);
  const { usersByUsername } = useUsersByUsername();

  const {
    handleSubmit,
    control,
    setValue,
    formState: { isDirty },
    watch,
  } = useForm<CompletedTaskForm>({
    defaultValues: {
      taskId: ganttTask.task.id,
      closedBy: ganttTask.completedTask?.closedBy?.username ?? auth.username ?? '',
      closedDate: ganttTask.completedTask?.closedDate ?? new Date().toISOString(),
      turnbackId: ganttTask.completedTask?.turnbackId,
      note: ganttTask.completedTask?.note ?? '',
      escapeTypeId: ganttTask.completedTask?.escapeTypeId,
      escapeNote: ganttTask.completedTask?.escapeNote ?? '',
      status: ganttTask.status,
    },
    resolver: zodResolver(refineCompletedTaskFormSchema()),
  });

  const status = watch('status');

  const initialStatus = ganttTask.status;
  const askConfirmation =
    status !== initialStatus &&
    (initialStatus === TaskStatus.Late || (initialStatus === TaskStatus.OnTime && status !== TaskStatus.Late));

  async function onSubmit(values: CompletedTaskForm) {
    if (status === TaskStatus.Open || status === TaskStatus.Overdue) {
      if (initialStatus !== TaskStatus.Open && initialStatus !== TaskStatus.Overdue && ganttTask.completedTask) {
        await deleteCompletedTask({ ...values, id: ganttTask.completedTask.id }).unwrap();
      }
    } else {
      if (initialStatus === TaskStatus.Open || initialStatus === TaskStatus.Overdue) {
        await createCompletedTask({ status, ...values, scheduledDate: ganttTask.date }).unwrap();
      } else if (ganttTask.completedTask) {
        await updateCompletedTask({
          ...values,
          id: ganttTask.completedTask.id,
          status,
          scheduledDate: ganttTask.date,
        }).unwrap();
      }
    }
    onClose();
  }

  const closeDate = parseISODate(ganttTask.completedTask?.closedDate);
  const hasNotes = status !== TaskStatus.Open && status !== TaskStatus.Overdue && status !== TaskStatus.NotApplicable;

  const error =
    createCompletedTaskRequest.error ?? updateCompletedTaskRequest.error ?? deleteCompletedTaskRequest.error;

  const departmentsById = useDepartmentsById();
  const scenariosById = useScenariosById();
  const processesById = useProcessesById();
  const subprocessesById = useSubprocessesById();

  const department = departmentsById.get(ganttTask.task.departmentId ?? -1);
  const scenario = scenariosById.get(ganttTask.task.scenarioId ?? -1);
  const process = processesById.get(ganttTask.task.processId ?? -1);
  const subprocess = subprocessesById.get(ganttTask.task.subprocessId ?? -1);

  return (
    <>
      <Dialog fullWidth maxWidth="lg" {...dialogProps}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle> {ganttTask.task.description}</DialogTitle>
          <DialogContent sx={{ display: 'flex', flexDirection: 'row', gap: 2, alignItems: 'flex-start' }}>
            <Card variant="outlined" sx={{ padding: 1, minWidth: 345 }}>
              <DefinitionList sx={{ gridTemplateColumns: '120px 1fr' }}>
                {ganttTask.task.dueHour && (
                  <>
                    <Typography component="dt">Due</Typography>
                    <Typography component="dd">
                      <span>{formatDate(new Date(ganttTask.date), 'P')}</span>
                      <Typography fontWeight="normal">{' at '}</Typography>
                      <span>{formatHour(ganttTask.task.dueHour)}</span>
                    </Typography>
                  </>
                )}
                {process ? (
                  <>
                    <Typography component="dt">Process</Typography>
                    <Typography component="dd">{process.shortName}</Typography>
                  </>
                ) : null}
                {closeDate && (
                  <>
                    <Typography component="dt">Closed</Typography>
                    <Typography component="dd">
                      <span>{formatDate(closeDate, 'P')}</span>
                      <Typography fontWeight="normal">{' at '}</Typography>
                      <span>{formatDateHour(closeDate)}</span>
                    </Typography>
                  </>
                )}
                {subprocess && (
                  <>
                    <Typography component="dt">Sub-process</Typography>
                    <Typography component="dd">{subprocess.name}</Typography>
                  </>
                )}
                {ganttTask?.completedTask?.closedBy && (
                  <>
                    <Typography component="dt">Closed by</Typography>
                    <Typography component="dd">{printUserName(ganttTask.completedTask.closedBy)}</Typography>
                  </>
                )}
                {scenario ? (
                  <>
                    <Typography component="dt">Scenario</Typography>
                    <Typography component="dd">
                      <ScenarioLabel scenario={scenario} />
                    </Typography>
                  </>
                ) : null}
                {ganttTask.task.reviewer && (
                  <>
                    <Typography component="dt">Reviewer</Typography>
                    <Typography component="dd">
                      {printUserName(usersByUsername.get(ganttTask.task.reviewer))}
                    </Typography>
                  </>
                )}
                {department && (
                  <>
                    <Typography component="dt">Department</Typography>
                    <Typography component="dd">{department.name}</Typography>
                  </>
                )}
              </DefinitionList>
              <DefinitionList sx={{ gridTemplateColumns: '120px 1fr' }}>
                <Typography component="dt">Entry number</Typography>
                <Typography component="dd">{ganttTask.task.entryNumber ?? '—'}</Typography>
                <Typography component="dt">Entry type</Typography>
                <Typography component="dd">{ganttTask.task.entryType ?? '—'}</Typography>
                <Typography component="dt">Reversal</Typography>
                <Typography component="dd">{ganttTask.task.reversal ?? '—'}</Typography>
              </DefinitionList>
            </Card>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="status"
                  render={({ field }) => (
                    <RadioGroup
                      row
                      aria-labelledby="status-buttons-group-label"
                      name="status-buttons-group"
                      value={field.value as TaskStatus}
                      onChange={(e) => {
                        const status = e.target.value as TaskStatus;
                        if (status === TaskStatus.Open || status === TaskStatus.Overdue) {
                          setValue('turnbackId', undefined as unknown as CompletedTaskForm['turnbackId']);
                        }
                        field.onChange(status);
                      }}
                    >
                      <Stack direction="row" gap={1} flexWrap="wrap" width="100%" marginBottom={1}>
                        <StatusButton
                          color="error"
                          status={field.value as TaskStatus}
                          value={TaskStatus.Open}
                          label={`${taskStatusLabels[TaskStatus.Open]} / ${taskStatusLabels[TaskStatus.Overdue]}`}
                        />
                        <StatusButton
                          color="default"
                          status={field.value as TaskStatus}
                          value={TaskStatus.NotApplicable}
                          label={taskStatusLabels[TaskStatus.NotApplicable]}
                        />
                      </Stack>
                      <Stack direction="row" gap={1} flexWrap="wrap" width="100%" marginBottom={2}>
                        <StatusButton
                          color="success"
                          status={field.value as TaskStatus}
                          value={TaskStatus.OnTime}
                          label={taskStatusLabels[TaskStatus.OnTime]}
                          disabled={!auth.isAdmin && isPast(new Date(ganttTask.date))}
                        />
                        <StatusButton
                          color="warning"
                          status={field.value as TaskStatus}
                          value={TaskStatus.Late}
                          label={taskStatusLabels[TaskStatus.Late]}
                        />
                      </Stack>
                    </RadioGroup>
                  )}
                />
              </Grid>
              {(status === TaskStatus.Late || status === TaskStatus.OnTime) && (
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="turnbackId"
                    render={({ field, fieldState: { error } }) => (
                      <TurnbackSelect {...field} error={Boolean(error)} helperText={error?.message} fullWidth />
                    )}
                  />
                </Grid>
              )}
              {hasNotes && (
                <Grid item xs={12}>
                  <Controller
                    control={control}
                    name="note"
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        label="Turnback notes"
                        {...field}
                        error={Boolean(error)}
                        helperText={error?.message}
                        multiline
                        fullWidth
                        rows={3}
                      />
                    )}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="escapeTypeId"
                  render={({ field, fieldState: { error } }) => (
                    <EscapeSelect {...field} error={Boolean(error)} helperText={error?.message} fullWidth />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  control={control}
                  name="escapeNote"
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      label="Escape notes"
                      {...field}
                      error={Boolean(error)}
                      helperText={error?.message}
                      multiline
                      fullWidth
                      rows={3}
                    />
                  )}
                />
              </Grid>
              {askConfirmation ? (
                <Grid item xs={12}>
                  <Alert
                    severity="warning"
                    sx={{
                      alignItems: 'center',
                      marginTop: 1,
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox checked={confirmChecked} onChange={() => setConfirmChecked((c) => !c)} size="small" />
                      }
                      label={
                        <span>
                          {
                            'I confirm I want to change the task status and lose any information associated with the current status'
                          }
                        </span>
                      }
                      sx={{ marginLeft: 0.5, '.MuiTypography-root': { marginLeft: 1 } }}
                    />
                  </Alert>
                </Grid>
              ) : null}
            </Grid>
          </DialogContent>
          {error ? (
            <DialogContent>
              <ErrorAlert error={error} sx={{ marginBottom: 2 }} />
            </DialogContent>
          ) : null}
          <DialogActions>
            <Button color="inherit" onClick={() => onClose()} sx={{ marginRight: 'auto' }}>
              {'Cancel'}
            </Button>
            <LoadingButton
              type="submit"
              color="primary"
              variant="contained"
              loading={
                createCompletedTaskRequest.isLoading ||
                updateCompletedTaskRequest.isLoading ||
                deleteCompletedTaskRequest.isLoading
              }
              disabled={(!isDirty && status === initialStatus) || (askConfirmation && !confirmChecked)}
            >
              {status === initialStatus ? 'Save' : 'Update status'}
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
