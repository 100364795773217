import { useMemo } from 'react';
import { useAuth } from '@top-solution/microtecnica-utils';
import { differenceInDays, add, getDay, isSameDay, isPast, isAfter, startOfDay } from 'date-fns';
import { CompletedTask, GanttTask, Task, TaskStatus } from '../entities/Task';
import { useReadTaskListQuery, useReadCompletedTaskListQuery } from '../services/taskApi';
import { useTaskScheduling } from './useTaskScheduling';
import { useUsersByUsername } from './useUsersByUsername';

const defaultPolling = 10 * 60 * 1000;

// export type UseGanttTasksParams = {
//   from: Date | null;
//   to: Date | null;
//   /**
//    * Filter tasks by task owner. All filters are in OR.
//    */
//   owner?: User['username'] | null;
//   /**
//    * Filter tasks by task backup. All filters are in OR.
//    */
//   backup?: User['username'] | null;
//   /**
//    * Filter tasks by task reviewer. All filters are in OR.
//    */
//   reviewer?: User['username'] | null;
//   /**
//    * Filter tasks by username (owner, backup, or reviewer). All filters are in OR.
//    */
//   username?: User['username'] | null;
//   /**
//    * Skips all data fetching.
//    */
//   skip?: boolean;
// };

type GanttTaskNotScheduled = GanttTask & {
  reason: string;
  day: string;
};

const from = startOfDay(new Date(2022, 0, 1));
const to = startOfDay(add(new Date(), { months: 12 }));

export function useGanttTasks() {
  const { usersByUsername } = useUsersByUsername();
  const { username } = useAuth();
  const user = username ? usersByUsername.get(username) : undefined;

  const { dayHasTask, isWorkingDay } = useTaskScheduling(from.getFullYear(), to.getFullYear() + 1);

  const readTaskListQuery = useReadTaskListQuery(
    { limit: 0, offset: 0 },
    {
      // skip: !params.from || !params.to || params.skip,
      pollingInterval: defaultPolling,
    },
  );

  const readCompletedTaskListQuery = useReadCompletedTaskListQuery();

  const completedTasksByTaskId = useMemo(() => {
    const completedTasksByTaskId: Record<Task['id'], CompletedTask[]> = {};
    for (const c of readCompletedTaskListQuery.data ?? []) {
      if (!completedTasksByTaskId[c.taskId]) {
        completedTasksByTaskId[c.taskId] = [];
      }

      completedTasksByTaskId[c.taskId].push(c);
    }

    return completedTasksByTaskId;
  }, [readCompletedTaskListQuery?.data]);

  const [ganttTasks, notScheduled]: [GanttTask[], GanttTaskNotScheduled[]] = useMemo(() => {
    const gts: GanttTask[] = [];
    const notScheduled: GanttTaskNotScheduled[] = [];

    const isDebugLoggingEnabled = localStorage.getItem('enableDebugLogging');

    if (!readTaskListQuery.data) {
      return [[], []];
    }

    if (readTaskListQuery.data.length === 0) {
      return [gts, notScheduled];
    }

    // eslint-disable-next-line no-console
    console.log('useGanttTasks: calculating tasks...');

    const fromToDifference = differenceInDays(to, from) + 1;

    for (let i = 0; i < fromToDifference; i++) {
      const day = add(from, { days: i });
      const dayGanttTasks: GanttTask[] = [];

      // const user = usersByUsername.get(task.owner);

      const dayTimetable = user?.timetable?.find((t) => {
        return t.day === getDay(day);
      });

      for (const task of readTaskListQuery.data) {
        const { hasTask, reason, overrideTask } = dayHasTask(task, day);

        // Exclude the task if it is not scheduled on the sample date
        if (!hasTask) {
          if (isDebugLoggingEnabled) {
            notScheduled.push({
              task,
              completedTask: undefined,
              date: day.toISOString(),
              status: TaskStatus.NotApplicable,
              reason: `dayHasTask returned false ${reason}`,
              day: day.toISOString(),
            });
          }

          continue;
        }

        // The task scheduled date + scheduled time
        const dueDateTime = add(day, { seconds: task.dueHour ? task.dueHour : 0 });

        // Exclude the task if it was created after the sample date
        if (isAfter(new Date(task.createdDate), dueDateTime)) {
          if (isDebugLoggingEnabled) {
            notScheduled.push({
              task,
              completedTask: undefined,
              date: day.toISOString(),
              status: TaskStatus.NotApplicable,
              reason: `[${day.toISOString()}] the task was created after this date (created on ${task.createdDate})`,
              day: day.toISOString(),
            });
          }

          continue;
        }

        // Exclude the task if it was deleted before the sample date
        if (task.deletedDate && isAfter(dueDateTime, new Date(task.deletedDate))) {
          if (isDebugLoggingEnabled) {
            notScheduled.push({
              task,
              completedTask: undefined,
              date: day.toISOString(),
              status: TaskStatus.NotApplicable,
              reason: `[${day.toISOString()}] the task was deleted before this date (deleted on ${task.deletedDate})`,
              day: day.toISOString(),
            });
          }

          continue;
        }

        // Find a completed task for the sample date
        let completedTask;

        // If there is an override task, use the completed task for the override for the current day
        // Using this, the task for any specific day will be considered completed regardless
        // of the presence of an override
        const idForCompletedTask = overrideTask ? overrideTask?.id : task.id;

        const completedTasks = completedTasksByTaskId[idForCompletedTask] ?? [];
        for (const c of completedTasks) {
          if (!c.scheduledDate) {
            continue;
          }
          if (!isSameDay(new Date(c.scheduledDate), day)) {
            continue;
          }
          completedTask = c;
        }

        // Assign a status on the GanttTask
        let taskStatus = TaskStatus.Open;
        if (completedTask) {
          taskStatus = completedTask.status ?? TaskStatus.Open;
        } else if (isPast(dueDateTime)) {
          taskStatus = TaskStatus.Overdue;
        }

        // Finally assemble the GanttTask
        const gt: GanttTask = {
          task: {
            ...task,
            ...overrideTask,
          },
          completedTask,
          date: dueDateTime.toISOString(),
          status: taskStatus,
          overrideTask,
        };

        gts.push(gt);
        dayGanttTasks.push(gt);
      }

      if (dayGanttTasks.length > 0) {
        if (dayTimetable && dayTimetable.startAfternoon > 0 && dayTimetable.endMorning > 0) {
          if (isWorkingDay(day)) {
            gts.push({
              date: day.toISOString(),
              task: {
                id: -1,
                description: 'Lunch break',
                dueHour: dayTimetable.startAfternoon,
                duration: dayTimetable.startAfternoon - dayTimetable.endMorning,
                createdDate: new Date(0).toISOString(),
                owner: '',
                reviewer: '',
                areaId: -1,
                processId: -1,
              },
              lunchBreak: true,
              status: TaskStatus.Open,
            });
          }
        }
      }
    }

    return [gts, notScheduled];
  }, [readTaskListQuery.data, user?.timetable, dayHasTask, completedTasksByTaskId, isWorkingDay]);

  return useMemo(() => {
    const ret = {
      ganttTasks,
      notScheduled,
      error: readTaskListQuery.error ?? readCompletedTaskListQuery.error,
      isFetching: readTaskListQuery.isFetching || readCompletedTaskListQuery.isFetching,
      isLoading: readTaskListQuery.isLoading || readCompletedTaskListQuery.isLoading,
      fulfilledTimeStamp:
        readTaskListQuery.fulfilledTimeStamp && readCompletedTaskListQuery.fulfilledTimeStamp
          ? Math.max(readTaskListQuery.fulfilledTimeStamp, readCompletedTaskListQuery.fulfilledTimeStamp)
          : undefined,
      // user,
      refetch: () => {
        readTaskListQuery.refetch();
        readCompletedTaskListQuery.refetch();
      },
    };

    if (localStorage.getItem('enableDebugLogging') === 'true') {
      // eslint-disable-next-line no-console
      console.debug('useGanttTasks', {
        ...ret,
        tasks: {
          scheduled: readTaskListQuery.data,
          notScheduled: notScheduled,
          completed: readCompletedTaskListQuery.data,
        },
      });
    }

    return ret;
  }, [ganttTasks, notScheduled, readTaskListQuery, readCompletedTaskListQuery]);
}
