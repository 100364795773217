import { useMemo } from 'react';
import { GanttTask } from '../../entities/Task';
import { GanttTasksStats, classifyTask } from '../../hooks/useGanttTasksStats';
import { useScenariosById } from '../../hooks/useScenariosById';
import { useUsersByUsername } from '../../hooks/useUsersByUsername';

export function usePerformanceTasksStats(fromDate: Date, toDate: Date, ganttTasks: GanttTask[]) {
  const { usersByUsername } = useUsersByUsername();
  const scenariosById = useScenariosById();

  const tasksStatsByMonth = useMemo(() => {
    const ganttTasksByMonth: Record<number, GanttTask[]> = {};

    for (const ganttTask of ganttTasks) {
      const month = new Date(ganttTask.date).getMonth();
      if (!ganttTasksByMonth[month]) {
        ganttTasksByMonth[month] = [];
      }
      ganttTasksByMonth[month].push(ganttTask);
    }

    const tasksStatsByMonth: Record<number, GanttTasksStats> = {};
    for (let i = 0; i < 12; i++) {
      const ganttTasks = ganttTasksByMonth[i];
      const tasksStats: GanttTasksStats = {
        cardColor: 'success',
        finance: {
          total: 0,
          totalDeliverable: 0,
          totalNotDeliverable: 0,
          onTime: 0,
          onTimeDeliverable: 0,
          completion: 0,
          completionDeliverable: 0,
          completionNotDeliverable: 0,
          escapeCount: 0,
        },
        supplier: {
          total: 0,
          totalDeliverable: 0,
          totalNotDeliverable: 0,
          onTime: 0,
          onTimeDeliverable: 0,
          completion: 0,
          completionDeliverable: 0,
          completionNotDeliverable: 0,
          escapeCount: 0,
        },
        count: {
          overdue: [],
          open: [],
          notApplicable: [],
          onTime: [],
          late: [],
          total: [],
        },
        byUser: {},
      };

      if (ganttTasks) {
        for (const ganttTask of ganttTasks) {
          classifyTask(ganttTask, tasksStats, usersByUsername, scenariosById, {
            excludeFutureTasks: false,
            ignoreOtherScenario: false,
          });
        }
      }

      if (tasksStats.count.overdue.length > 0) {
        tasksStats.cardColor = 'error';
      }

      tasksStatsByMonth[i] = tasksStats;
    }

    return tasksStatsByMonth;
  }, [ganttTasks, scenariosById, usersByUsername]);

  return useMemo(() => {
    const ret = {
      fromDate,
      toDate,
      tasksStatsByMonth,
    };

    // eslint-disable-next-line no-console
    console.log('usePerformanceTasksStats(fromDate, toDate): ', fromDate, toDate, ret);

    return ret;
  }, [fromDate, tasksStatsByMonth, toDate]);
}
