import { useMemo } from 'react';
import { DataGrid, DataGridProps, DataGridWrapper } from '@top-solution/microtecnica-mui';
import { isSameDay } from 'date-fns';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import {
  GridActionsCellItem,
  GridColDef,
  GridColumnVisibilityModel,
  GridRenderCellParams,
  GridRowClassNameParams,
  gridClasses,
} from '@mui/x-data-grid-premium';
import { CheckCircleOutlineIcon } from '../../components/Icons';
import { PAGE_TITLE_OFFSET_HEIGHT } from '../../components/PageTitle';
import { GanttTask } from '../../entities/Task';
import { DEFAULT_END_AFTERNOON_HOUR, DEFAULT_START_MORNING_HOUR } from '../../entities/UserTimetable';
import { useCategoryColDef } from '../../hooks/useCategoryColDef';
import { useDeliverableColDef } from '../../hooks/useDeliverableColDef';
import { useDocumentUrlColDef } from '../../hooks/useDocumentUrlColDef';
import { useEscapeColDefs } from '../../hooks/useEscapeColDef';
import { useGanttTaskDescriptionColDef } from '../../hooks/useGanttTaskDescriptionColDef';
import { usePlannerConfig } from '../../hooks/usePlannerConfig';
import { useProcessColDef } from '../../hooks/useProcessColDef';
import { useScenarioColDef } from '../../hooks/useScenarioColDef';
import { useSubprocessColDef } from '../../hooks/useSubprocessColDef';
import { useTaskStatusColDef } from '../../hooks/useTaskStatusColDef';
import { useTurnbackColDef } from '../../hooks/useTurnbackColDef';
import { useUsersColDefs } from '../../hooks/useUsersColDefs';
import { dateColumn, durationColumn, formatHour, hourColumn } from '../../utils/date';
import { GanttTaskBar } from './GanttTaskBar';

function CustomNoRowsOverlay() {
  return (
    <Stack direction="row" alignItems="center" justifyContent="center" height="100%">
      <Box>{'Please select at least one filter among Owner, Backup and Reviewer'}</Box>
    </Stack>
  );
}

type GanttDataGridProps = Omit<DataGridProps, 'columns' | 'rows'> & {
  ganttTasks: GanttTask[];
  onTime: number | undefined;
  zoomLevel: number;
  onTaskClick: (task: GanttTask) => void;
  selectFilterMessage: boolean;
};

const pinnedColumns = { left: ['description'], right: ['actions'] };

export function GanttDataGrid(props: GanttDataGridProps): JSX.Element {
  const { ganttTasks, onTime, zoomLevel, onTaskClick, selectFilterMessage, ...dataGridProps } = props;
  const processColumn = useProcessColDef();
  const subprocessColumn = useSubprocessColDef();
  const scenarioColumn = useScenarioColDef();
  const categoryColumn = useCategoryColDef();
  const taskStatusColumn = useTaskStatusColDef();
  const turnbackColumn = useTurnbackColDef();
  const { escapeColumn, escapeNoteColumn } = useEscapeColDefs();
  const deliverableColumn = useDeliverableColDef();
  const documentUrlColumn = useDocumentUrlColDef();
  const { ownerColumn, backupColumn, reviewerColumn } = useUsersColDefs<GanttTask>();
  const { config, patchConfig } = usePlannerConfig();

  const descriptionColumn = useGanttTaskDescriptionColDef(onTime);

  const { rows, hourMin, hourMax } = useMemo<{ rows: GanttTask[]; hourMin: number; hourMax: number }>(() => {
    let hourMin = DEFAULT_START_MORNING_HOUR * 60 * 60;
    let hourMax = DEFAULT_END_AFTERNOON_HOUR * 60 * 60;

    const rows = [...ganttTasks]
      .map((ganttTask) => ({
        ...ganttTask,
        id: ganttTask.lunchBreak ? `lunchbreak__${ganttTask.date}` : `${ganttTask.task.id}__${ganttTask.date}`,
      }))
      .sort((a, b) => (a.date < b.date ? -1 : a.date > b.date ? 1 : (a.task.dueHour ?? -1) - (b.task.dueHour ?? -1)));

    for (let i = 0; i < rows.length; i++) {
      const row = rows[i];
      hourMin = Math.min(hourMin, row.task.dueHour ? row.task.dueHour - (row.task.duration ?? 0) : hourMin);
      hourMax = Math.max(hourMax, row.task.dueHour ? row.task.dueHour : hourMax);
      const nextRow = rows[i + 1];
      if (nextRow) {
        if (!isSameDay(new Date(row.date), new Date(nextRow.date))) {
          row.isLastOfDay = true;
        }
      }
    }

    return { rows, hourMin, hourMax };
  }, [ganttTasks]);

  const hourColumns = [...new Array(2 + Math.ceil((hourMax - hourMin) / 60 / 60))].map((_, h) => hourMin + h * 3600);

  const columns = useMemo<GridColDef<GanttTask>[]>(() => {
    const cols: GridColDef<GanttTask>[] = [
      descriptionColumn,
      {
        ...taskStatusColumn,
        valueGetter: (value, row: GanttTask) => (row.lunchBreak ? null : row.status),
      },
      { ...documentUrlColumn, valueGetter: (value, row: GanttTask) => row.task?.documentUrl },
      { ...deliverableColumn },
      { ...turnbackColumn },
      { ...escapeColumn },
      { ...escapeNoteColumn },
      ownerColumn((row) => row.task.owner),
      backupColumn((row) => row.task.backup),
      reviewerColumn((row) => row.task.reviewer),
      { ...processColumn },
      { ...subprocessColumn },
      { ...scenarioColumn },
      { ...categoryColumn },
      {
        ...dateColumn,
        field: 'date',
        headerName: 'Due date',
        width: 100,
      },
      {
        ...durationColumn,
        field: 'duration',
        headerName: 'Duration',
        width: 80,
        valueGetter: (value, row: GanttTask) => row.task.duration,
      },
      {
        ...hourColumn,
        field: 'hourStart',
        valueGetter: (value, row: GanttTask) =>
          row.task.dueHour && row.task.dueHour > 0
            ? row.task.dueHour - (row.task.duration === -1 ? 5 : row.task.duration)
            : undefined,
        headerName: 'Start',
        width: 60,
      },
      {
        ...hourColumn,
        field: 'hourEnd',
        headerName: 'End',
        width: 60,
        valueGetter: (value, row: GanttTask) => row.task.dueHour,
      },
      ...hourColumns.map<GridColDef<GanttTask>>((hour) => ({
        field: `hour${hour}`,
        headerName: formatHour(hour),
        disableColumnMenu: true,
        disableReorder: true,
        sortable: false,
        filterable: false,
        resizable: false,
        width: 60 + zoomLevel * 20,
        cellClassName: 'HourCell',
        headerAlign: 'center',
        hideable: false,
        renderCell: ({ row }: GridRenderCellParams<GanttTask>) => (
          <GanttTaskBar hour={hour} task={row} onClick={onTaskClick} />
        ),
      })),
      {
        field: 'actions',
        type: 'actions',
        headerName: '',
        hideable: false,
        width: 36,
        getActions: ({ row }) => {
          if (row.lunchBreak) {
            return [];
          }
          return [
            <GridActionsCellItem
              icon={<CheckCircleOutlineIcon />}
              label="Modifica"
              key={'edit'}
              className="textPrimary"
              onClick={() => {
                onTaskClick(row);
              }}
              color="inherit"
            />,
          ];
        },
      },
    ];

    return cols.map((colDef) => ({
      ...colDef,
      sortable: false,
    }));
  }, [
    descriptionColumn,
    taskStatusColumn,
    documentUrlColumn,
    deliverableColumn,
    turnbackColumn,
    escapeColumn,
    escapeNoteColumn,
    ownerColumn,
    backupColumn,
    reviewerColumn,
    processColumn,
    subprocessColumn,
    scenarioColumn,
    categoryColumn,
    hourColumns,
    zoomLevel,
    onTaskClick,
  ]);

  return (
    <DataGridWrapper
      offsetHeight={PAGE_TITLE_OFFSET_HEIGHT}
      sx={{
        '.MuiDataGrid-cell.HourCell': {
          padding: 0,
          position: 'relative',
          overflow: 'visible!important',
          borderLeft: '1px solid',
          borderLeftColor: 'grey.200',

          '&:focus, &:focus-within': {
            outlineWidth: 0,

            '> button': {
              outline: 'inherit',
              outlineWidth: 1,
            },
          },

          '&::after, &::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            bottom: 0,
            bgcolor: 'grey.50',
            width: '25%',
            zIndex: -1,
          },
          '&::before': {
            left: '25%',
          },
          '&::after': {
            left: '75%',
          },
        },
      }}
    >
      <DataGrid
        sessionStorageId="gantt-grid"
        columns={columns}
        rows={rows}
        initialState={{
          density: 'standard',
          columns: {
            columnVisibilityModel: (config?.pages?.gantt?.datagrid
              ?.columnVisibilityModel as GridColumnVisibilityModel) ?? {
              turnback: false,
              escapeTypeId: false,
              escapeNote: false,
            },
          },
        }}
        pinnedColumns={pinnedColumns}
        filterMode="client"
        getRowClassName={({ row }: GridRowClassNameParams<GanttTask>) => {
          let className = row.lunchBreak ? 'lunch-break' : '';
          if (row.isLastOfDay) {
            className = `${className} last-of-day`;
          }
          return className;
        }}
        {...dataGridProps}
        slots={
          selectFilterMessage
            ? {
                noRowsOverlay: CustomNoRowsOverlay,
              }
            : undefined
        }
        disableAggregation
        disableRowGrouping
        onColumnVisibilityModelChange={(columnVisibilityModel) =>
          patchConfig({ pages: { overview: { datagrid: { columnVisibilityModel } } } })
        }
        sx={{
          '& .last-of-day .MuiDataGrid-cell': {
            borderBottom: `4px solid rgba(220,220,220,1)`,
          },
          '.description-cell': {
            paddingX: 0,
          },
          '& .lunch-break': {
            color: 'rgba(0,0,0,0.5)',

            '& .description-cell': {
              paddingX: 1.5,
            },
          },
          [`.${gridClasses.footerContainer} button:last-of-type`]: {
            display: 'none',
          },
        }}
      />
    </DataGridWrapper>
  );
}
