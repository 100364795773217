import { useMemo } from 'react';
import { isFuture } from 'date-fns';
import { EscapeTypes } from '../entities/Escape';
import { Process } from '../entities/Process';
import { Scenario } from '../entities/Scenario';
import { Subprocess } from '../entities/Subprocess';
import { GanttTask, TaskStatus } from '../entities/Task';
import { User, UserRoleName } from '../entities/User';
import { useScenariosById } from './useScenariosById';
import { useUsersByUsername } from './useUsersByUsername';

export interface GanttTasksUserStats {
  total: number;
  completion: number;
}

export interface GanttTasksStats {
  cardColor: 'success' | 'error';
  finance: {
    total: number;
    totalDeliverable: number;
    totalNotDeliverable: number;
    onTime: number;
    onTimeDeliverable: number;
    completion: number;
    completionDeliverable: number;
    completionNotDeliverable: number;
    escapeCount: number;
  };
  supplier: {
    total: number;
    totalDeliverable: number;
    totalNotDeliverable: number;
    onTime: number;
    onTimeDeliverable: number;
    completion: number;
    completionDeliverable: number;
    completionNotDeliverable: number;
    escapeCount: number;
  };
  count: {
    open: GanttTask[];
    overdue: GanttTask[];
    notApplicable: GanttTask[];
    onTime: GanttTask[];
    late: GanttTask[];
    total: GanttTask[];
  };
  byUser: Record<User['username'], GanttTasksUserStats>;
}

interface UseGanttTasksStatsOptions {
  excludeFutureTasks: boolean;
  ignoreOtherScenario: boolean;
}

export function isTaskFinance(ganttTask: GanttTask, usersByUsername: Map<User['username'], User>): boolean {
  const user = usersByUsername.get(ganttTask.task.owner);
  return user?.role !== UserRoleName.GUEST;
}

export function classifyTask(
  ganttTask: GanttTask,
  stats: GanttTasksStats,
  usersByUsername: Map<User['username'], User>,
  scenariosById: Map<Scenario['id'], Scenario>,
  options: UseGanttTasksStatsOptions,
) {
  const status = ganttTask.status;

  // Ignore tasks without scenario
  if (!ganttTask.task.scenarioId) {
    return;
  }

  // Ignore tasks with OTHER scenario (not relevant for stats)
  if (options.ignoreOtherScenario && scenariosById.get(ganttTask.task.scenarioId)?.name === 'OTHER') {
    return;
  }

  if (options.excludeFutureTasks) {
    // Ignore future tasks (Overview is only for the past)
    if (isFuture(new Date(ganttTask.date))) {
      return;
    }
  }

  // Show N/A tasks only in the N/A entry, exclude it from any other counting / statistic
  if (ganttTask.completedTask?.status === TaskStatus.NotApplicable) {
    stats.count.notApplicable.push(ganttTask);
    return;
  }

  // Task does not have an owner (??)
  if (!ganttTask.task.owner) {
    return;
  }

  stats.count.total.push(ganttTask);

  switch (status) {
    case TaskStatus.OnTime: {
      if (ganttTask.task.scenarioId) {
        stats.count.onTime.push(ganttTask);
      }
      break;
    }
    case TaskStatus.Late: {
      stats.count.late.push(ganttTask);
      break;
    }
    case TaskStatus.Overdue: {
      stats.count.overdue.push(ganttTask);
      break;
    }
    case TaskStatus.Open: {
      stats.count.open.push(ganttTask);
      break;
    }
  }

  if (!stats.byUser[ganttTask.task.owner]) {
    stats.byUser[ganttTask.task.owner] = {
      completion: 0,
      total: 0,
    };
  }

  const taskFinance = isTaskFinance(ganttTask, usersByUsername);

  if (taskFinance) {
    stats.finance.total = stats.finance.total + 1;

    if (ganttTask.task.isDeliverable) {
      stats.finance.totalDeliverable = stats.finance.totalDeliverable + 1;
    } else {
      stats.finance.totalNotDeliverable = stats.finance.totalNotDeliverable + 1;
    }

    stats.byUser[ganttTask.task.owner].total = stats.byUser[ganttTask.task.owner].total + 1;

    if (ganttTask.completedTask) {
      stats.finance.completion = stats.finance.completion + 1;
      if (ganttTask.task.isDeliverable) {
        stats.finance.completionDeliverable = stats.finance.completionDeliverable + 1;
      } else {
        stats.finance.completionNotDeliverable = stats.finance.completionNotDeliverable + 1;
      }

      stats.byUser[ganttTask.task.owner].completion = stats.byUser[ganttTask.task.owner].completion + 1;

      if (ganttTask.completedTask.status === TaskStatus.OnTime) {
        if (ganttTask.task.isDeliverable) {
          stats.finance.onTimeDeliverable = stats.supplier.onTimeDeliverable + 1;
        } else {
          stats.finance.onTime = stats.finance.onTime + 1;
        }
      }
    }
  } else {
    stats.supplier.total = stats.supplier.total + 1;

    if (ganttTask.task.isDeliverable) {
      stats.supplier.totalDeliverable = stats.supplier.totalDeliverable + 1;
    } else {
      stats.supplier.totalNotDeliverable = stats.supplier.totalNotDeliverable + 1;
    }

    stats.byUser[ganttTask.task.owner].total = stats.byUser[ganttTask.task.owner].total + 1;

    if (ganttTask.completedTask) {
      stats.supplier.completion = stats.supplier.completion + 1;
      if (ganttTask.task.isDeliverable) {
        stats.supplier.completionDeliverable = stats.supplier.completionDeliverable + 1;
      } else {
        stats.supplier.completionNotDeliverable = stats.supplier.completionNotDeliverable + 1;
      }

      stats.byUser[ganttTask.task.owner].completion = stats.byUser[ganttTask.task.owner].completion + 1;

      if (ganttTask.completedTask.status === TaskStatus.OnTime) {
        if (ganttTask.task.isDeliverable) {
          stats.supplier.onTimeDeliverable = stats.supplier.onTimeDeliverable + 1;
        } else {
          stats.supplier.onTime = stats.supplier.onTime + 1;
        }
      }
    }
  }

  if (ganttTask.completedTask?.escapeTypeId !== undefined) {
    if (
      ganttTask.completedTask.escapeTypeId === EscapeTypes.Significant ||
      ganttTask.completedTask.escapeTypeId === EscapeTypes.Other
    ) {
      if (taskFinance) {
        stats.finance.escapeCount = stats.finance.escapeCount + 1;
      } else {
        stats.supplier.escapeCount = stats.supplier.escapeCount + 1;
      }
    }
  }
}

export function useGanttTasksStats(
  ganttTasks: GanttTask[],
  filters: {
    processId?: Process['id'];
    subprocessId?: Subprocess['id'];
    scenarioName?: Scenario['name'];
    ownerUsername?: User['username'];
  },
  options: UseGanttTasksStatsOptions,
): GanttTasksStats {
  const { usersByUsername } = useUsersByUsername();
  const scenariosById = useScenariosById();

  return useMemo(() => {
    const tasksStats: GanttTasksStats = {
      cardColor: 'success',
      finance: {
        total: 0,
        totalDeliverable: 0,
        totalNotDeliverable: 0,
        onTime: 0,
        onTimeDeliverable: 0,
        completion: 0,
        completionDeliverable: 0,
        completionNotDeliverable: 0,
        escapeCount: 0,
      },
      supplier: {
        total: 0,
        totalDeliverable: 0,
        totalNotDeliverable: 0,
        onTime: 0,
        onTimeDeliverable: 0,
        completion: 0,
        completionDeliverable: 0,
        completionNotDeliverable: 0,
        escapeCount: 0,
      },
      count: {
        overdue: [],
        open: [],
        notApplicable: [],
        onTime: [],
        late: [],
        total: [],
      },
      byUser: {},
    };

    for (const ganttTask of ganttTasks) {
      if (filters.processId && ganttTask.task.processId !== filters.processId) {
        continue;
      }
      if (
        filters.scenarioName &&
        ganttTask.task.scenarioId !== undefined &&
        scenariosById.get(ganttTask.task.scenarioId)?.name !== filters.scenarioName
      ) {
        continue;
      }
      if (filters.subprocessId && ganttTask.task.subprocessId !== filters.subprocessId) {
        continue;
      }
      if (filters.ownerUsername && ganttTask.task.owner !== filters.ownerUsername) {
        continue;
      }
      classifyTask(ganttTask, tasksStats, usersByUsername, scenariosById, options);
    }

    if (tasksStats.count.overdue.length > 0) {
      tasksStats.cardColor = 'error';
    }

    // eslint-disable-next-line no-console
    console.log('useGanttTasksStats(filters, options): ', filters, options, tasksStats);

    return tasksStats;
  }, [filters, ganttTasks, options, scenariosById, usersByUsername]);
}
