import { DefaultValues } from 'react-hook-form';
import { z } from 'zod';
import { AreaSchema } from './Area';
import { CategorySchema } from './Category';
import { DepartmentSchema } from './Department';
import { EscapeTypes } from './Escape';
import { ProcessSchema } from './Process';
import { ScenarioSchema } from './Scenario';
import { SubprocessSchema } from './Subprocess';
import { TurnbackSchema } from './Turnback';
import { UserSchema } from './User';

export enum TaskStatus {
  Open = 'OPEN',
  OnTime = 'ON_TIME',
  Late = 'LATE',
  NotApplicable = 'NA',
  Overdue = 'OVERDUE',
}

export const taskStatusLabels = {
  [TaskStatus.Open]: 'Open',
  [TaskStatus.OnTime]: 'On time',
  [TaskStatus.Late]: 'Late',
  [TaskStatus.NotApplicable]: 'N/A',
  [TaskStatus.Overdue]: 'Overdue',
};

const TaskScheduleSchema = z.object({
  month: z.number(),
  day: z.number(),
  shift: z.number(),
  workDay: z.number(),
  occurrence: z.number(),
  weekDay: z.number(),
});

const TaskConfigurationSchema = z.object({
  areaId: AreaSchema.shape.id,
  backup: UserSchema.shape.username.optional(),
  categoryId: CategorySchema.shape.id.optional(),
  departmentId: DepartmentSchema.shape.id.optional(),
  description: z.string(),
  dueHour: z.number().optional(), // FIXME: stop using non-sense data types
  dueHourMachine: z.number().optional(), // FIXME: stop using non-sense data types
  duration: z.number(),
  durationMachine: z.number().optional(),
  entryNumber: z.number().optional(),
  entryType: z.string().min(1).optional(),
  owner: UserSchema.shape.username,
  processId: ProcessSchema.shape.id,
  reversal: z.string().min(1).optional(),
  reviewer: UserSchema.shape.username,
  scenarioId: ScenarioSchema.shape.id.optional(),
  subprocessId: SubprocessSchema.shape.id.optional(),
  isDeliverable: z.boolean().optional(),
});

const TaskOverrideSchema = TaskConfigurationSchema.extend({
  fatherId: z.number(),
  id: z.number(),
  overrideDay: z.string().datetime(),
  overrideOriginalDay: z.string().datetime(),
});

export const TaskSchema = TaskConfigurationSchema.extend({
  id: z.number(),
  fatherId: z.number().optional(),
  createdDate: z.string().datetime(),
  deletedDate: z.string().datetime().optional(),
  documentUrl: z.string().min(1).max(256).optional(),
  hasUpdatedTask: z.boolean().optional(),
  schedule: TaskScheduleSchema,
  overrides: z.array(TaskOverrideSchema).optional(), // TODO: not optional
});

export const TaskCreateRequestSchema = TaskSchema.omit({
  id: true,
  backup: true,
  owner: true,
  processId: true,
  reviewer: true,
  createdDate: true,
  deletedDate: true,
}).extend({
  backupUsername: UserSchema.shape.username.optional(),
  ownerUsername: UserSchema.shape.username,
  processId: z.number(),
  reviewerUsername: UserSchema.shape.username,
  updatedDate: z.string().optional(),
});

export const TaskOverrideCreateRequestSchema = TaskCreateRequestSchema.extend({
  fatherId: TaskSchema.shape.id,
  overrideDay: z.string().datetime(),
  overrideOriginalDay: z.string().datetime(),
});

export const TaskUserUpdateRequestSchema = TaskCreateRequestSchema.pick({
  description: true,
  processId: true,
  subprocessId: true,
}).extend({
  id: TaskSchema.shape.id,
});

export const TaskConfigurationFormSchema = TaskSchema.pick({
  areaId: true,
  departmentId: true,
  categoryId: true,
  description: true,
  documentUrl: true,
  entryNumber: true,
  entryType: true,
  isDeliverable: true,
  processId: true,
  reversal: true,
  scenarioId: true,
  subprocessId: true,
}).extend({
  isMachineTask: z.boolean(),
  backupUsername: UserSchema.shape.username.optional(),
  ownerUsername: UserSchema.shape.username,
  reviewerUsername: UserSchema.shape.username,
  confirmUserChange: z.literal(true),
  departmentId: TaskSchema.shape.departmentId.unwrap(),
});

export const TimeScheduleFormSchema = TaskSchema.pick({
  dueHourMachine: true,
  durationMachine: true,
}).extend({
  isMachineTask: z.boolean(),
  duration: z.number().min(1),
  dueHour: z.number().min(1),
});

export type TaskSchedule = z.infer<typeof TaskScheduleSchema>;
export type TaskOverride = z.infer<typeof TaskOverrideSchema>;
export type Task = z.infer<typeof TaskSchema>;
export type EditingTask = Omit<Task, 'id'> & { id?: Task['id'] };
export type TaskConfigurationForm = z.infer<typeof TaskConfigurationFormSchema>;
export type TaskCreateRequest = z.infer<typeof TaskCreateRequestSchema>;
export type TaskUserUpdateRequest = z.infer<typeof TaskUserUpdateRequestSchema>;
export type TaskOverrideCreateRequest = z.infer<typeof TaskOverrideCreateRequestSchema>;
export type TimeScheduleForm = z.infer<typeof TimeScheduleFormSchema>;

const TaskScheduleFormBaseSchema = z.object({
  month: z.number().min(1, 'Please select at least a month').max(Math.pow(2, 12)),
});

export const YearlyScheduleFormSchema = TaskScheduleFormBaseSchema.extend({
  day: z.number().min(0).max(+31),
  shift: z.number().min(-1).max(1),
});

export const WorkDayScheduleFormSchema = TaskScheduleFormBaseSchema.extend({
  workDay: z.number().min(-31).max(31),
});

export const WeekDayScheduleFormSchema = TaskScheduleFormBaseSchema.extend({
  occurrence: z.number().min(1, 'Please select at least an occurrence').max(Math.pow(2, 16)),
  weekDay: z.number().min(1, 'Please select at least a weekday').max(Math.pow(2, 7)),
});

export type YearlyScheduleForm = z.infer<typeof YearlyScheduleFormSchema>;
export type WorkDayScheduleForm = z.infer<typeof WorkDayScheduleFormSchema>;
export type WeekDayScheduleForm = z.infer<typeof WeekDayScheduleFormSchema>;

export interface ScheduleTabProps {
  previewOpen: boolean;
  onPreviewOpenChange: (open: boolean) => void;
  onClose: () => void;
  onSubmit: (recurrence: Partial<TaskSchedule>) => void;
  initialValues: DefaultValues<TaskSchedule> | null;
  dialogActions: React.ReactNode;
}

export const CompletedTaskSchema = z.object({
  // status: TaskStatusSchema,
  closedBy: UserSchema.optional(),
  closedDate: z.string().min(1).optional(),
  scheduledDate: z.string().min(1).optional(),
  id: z.number(),
  note: z.string().optional(),
  taskId: TaskSchema.shape.id,
  turnbackId: TurnbackSchema.shape.id.optional(),
  escapeTypeId: TurnbackSchema.shape.id.optional(),
  escapeNote: z.string().optional(),
  status: z.nativeEnum(TaskStatus).optional(),
});
export type CompletedTask = z.infer<typeof CompletedTaskSchema>;

const CompletedTaskFormSchema = CompletedTaskSchema.omit({
  id: true,
  status: true,
}).extend({
  closedBy: CompletedTaskSchema.shape.closedBy.unwrap().shape.username,
  status: CompletedTaskSchema.shape.status.optional(),
});

export function refineCompletedTaskFormSchema(): z.ZodSchema {
  return CompletedTaskFormSchema.superRefine((data, ctx) => {
    const status = data.status;
    if (status === TaskStatus.Late) {
      if (!data.turnbackId) {
        ctx.addIssue({
          path: ['turnbackId'],
          code: z.ZodIssueCode.invalid_type,
          expected: 'number',
          received: typeof data.turnbackId,
        });
      }
    }
    if (status === TaskStatus.Late) {
      if (!data.note || data.note.length < 1) {
        ctx.addIssue({
          path: ['note'],
          code: z.ZodIssueCode.too_small,
          minimum: 1,
          inclusive: true,
          type: 'string',
        });
      }
    }
    if (data.escapeTypeId === EscapeTypes.Other || data.escapeTypeId === EscapeTypes.Significant) {
      if (!data.escapeNote || data.escapeNote.length < 1) {
        ctx.addIssue({
          path: ['escapeNote'],
          code: z.ZodIssueCode.too_small,
          minimum: 1,
          inclusive: true,
          type: 'string',
        });
      }
    }
  });
}

export type CompletedTaskForm = z.infer<typeof CompletedTaskFormSchema>;

const GanttTaskSchema = z.object({
  task: TaskSchema.omit({ schedule: true }).extend({
    schedule: TaskSchema.shape.schedule.optional(),
  }),
  completedTask: CompletedTaskSchema.optional(),
  date: z.string(),
  lunchBreak: z.boolean().optional(),
  status: z.nativeEnum(TaskStatus),
  isLastOfDay: z.boolean().optional(),
  overrideTask: TaskOverrideSchema.optional(),
});
export type GanttTask = z.infer<typeof GanttTaskSchema>;

const NotificationsGanttTaskSchema = GanttTaskSchema.omit({ task: true }).extend({
  task: TaskSchema.omit({ schedule: true, owner: true, reviewer: true }).extend({
    owner: UserSchema.optional(),
    reviewer: UserSchema.optional(),
  }),
});

export type NotificationsGanttTask = z.infer<typeof NotificationsGanttTaskSchema>;
