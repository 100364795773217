import { User } from '../entities/User';
import { DayTimetable } from '../entities/UserTimetable';

export function printUserName(user?: Pick<User, 'firstName' | 'lastName' | 'username' | 'id'>) {
  if (!user) {
    return '—';
  }

  if (user?.lastName) {
    return `${user.lastName} ${user.firstName}`;
  }

  if ('username' in user && user.username) {
    return user.username;
  }

  return `${user.id}`;
}

// FIXME: find a better name
export function printUserNameWithUsername(user?: Pick<User, 'firstName' | 'lastName' | 'username' | 'id'>) {
  if (!user) {
    return '—';
  }

  return `${printUserName(user)} (${user.username})`;
}

export function weekDayWorkingSeconds(timetable?: DayTimetable): number {
  return timetable
    ? (timetable.endMorning ?? 0) -
        (timetable.startMorning ?? 0) +
        (timetable.endAfternoon ?? 0) -
        (timetable.startAfternoon ?? 0)
    : 8 * 60 * 60;
}
