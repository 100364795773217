import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ChevronLeftIcon, ChevronRightIcon, ChevronDownIcon } from '../../../../../../components/Icons';
import { Title } from '../Title';
import { PreviewMonth } from './PreviewMonth';
import { PreviewProps } from './PreviewProps';

function PreviewComponent(props: PreviewProps) {
  const { open, onToggle } = props;
  return (
    <Accordion
      expanded={open}
      variant="outlined"
      onChange={() => {
        onToggle(!open);
        props.onYearChange(new Date().getFullYear());
      }}
      sx={{
        borderRadius: 1,
        '&,&.Mui-expanded': {
          margin: 0,
        },
        '&::before': {
          display: 'none',
        },
      }}
    >
      <AccordionSummary
        expandIcon={<ChevronDownIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        sx={{
          display: 'flex',
          '&,&.Mui-expanded': {
            minHeight: 48,
            maxHeight: 48,
          },
          '.MuiAccordionSummary-content': { alignItems: 'center', gap: 4, paddingRight: 4 },
        }}
      >
        <Title gutterBottom={false} title={'Preview'}></Title>
        <Typography
          component="div"
          sx={{ opacity: open ? 1 : 0, transition: (theme) => theme.transitions.create('opacity') }}
        >
          <Stack
            direction="row"
            alignItems="center"
            gap={1}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <IconButton onClick={() => props.onYearChange(props.year - 1)}>{<ChevronLeftIcon />}</IconButton>
            {props.year}
            <IconButton onClick={() => props.onYearChange(props.year + 1)}>{<ChevronRightIcon />}</IconButton>
          </Stack>
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ overflow: 'hidden' }}>
        <Grid container rowSpacing={1} columnSpacing={2}>
          {Array.from(new Array(12)).map((_, i) => {
            return (
              <Grid key={i} item xs={6} md={4} lg={3} xl={2}>
                <PreviewMonth month={i} {...props} />
              </Grid>
            );
          })}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

export const Preview = React.memo(PreviewComponent);
