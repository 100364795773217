import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useFilteredGanttTasks } from '../../../components/FilteredGanttTasks';
import { useGanttTasksStats } from '../../../hooks/useGanttTasksStats';
import { useReadProcessListQuery } from '../../../services/processApi';
import { useReadScenarioListQuery } from '../../../services/scenarioApi';
import { useReadSubprocessListQuery } from '../../../services/subprocessApi';
import { formatPercentage } from '../../../utils/valueHelpers';
import { useOverviewQueryParams } from '../overviewQueryParams';

function OverviewTitleComponent() {
  const params = useParams<{
    processId?: string;
    scenarioName?: string;
    subprocessId?: string;
    ownerUserName?: string;
  }>();

  const tasksPage = window.location.pathname.match(/\/tasks$/);

  const processList = useReadProcessListQuery();
  const scenarioList = useReadScenarioListQuery();
  const subprocessList = useReadSubprocessListQuery();

  const process = useMemo(
    () =>
      params.processId !== undefined &&
      processList.data &&
      processList.data.find((p) => p.id === Number(params.processId)),
    [params.processId, processList.data],
  );

  const scenario = useMemo(
    () =>
      params.scenarioName !== undefined &&
      scenarioList.data &&
      scenarioList.data.find((p) => p.name === params.scenarioName),
    [params.scenarioName, scenarioList.data],
  );

  const subprocess = useMemo(
    () =>
      params.subprocessId !== undefined &&
      subprocessList.data &&
      subprocessList.data.find((p) => p.id === Number(params.subprocessId)),
    [params.subprocessId, subprocessList.data],
  );

  const { excludeFutureTasks } = useOverviewQueryParams();

  const { filteredGanttTasks, isLoading: ganttTasksIsLoading } = useFilteredGanttTasks();
  const { finance, supplier } = useGanttTasksStats(
    filteredGanttTasks,
    {
      processId: params.processId ? Number(params.processId) : undefined,
      scenarioName: params.scenarioName,
      subprocessId: params.subprocessId ? Number(params.subprocessId) : undefined,
      ownerUsername: params.ownerUserName,
    },
    {
      excludeFutureTasks,
      ignoreOtherScenario: true,
    },
  );

  const onTime =
    supplier.completionNotDeliverable + finance.completionNotDeliverable === 0
      ? undefined
      : (supplier.onTime + finance.onTime) / (supplier.completionNotDeliverable + finance.completionNotDeliverable);
  const completion =
    supplier.total + finance.total === 0
      ? undefined
      : (supplier.completion + finance.completion) / (supplier.total + finance.total);
  const onTimeDeliverable =
    supplier.onTimeDeliverable + finance.onTimeDeliverable === 0
      ? undefined
      : (supplier.onTimeDeliverable + finance.onTimeDeliverable) /
        (supplier.completionDeliverable + finance.completionDeliverable);

  const loading = processList.isLoading || scenarioList.isLoading || subprocessList.isLoading || ganttTasksIsLoading;

  return (
    <>
      <Typography variant="h5" lineHeight="1.8" sx={{ marginRight: 2 }}>
        {tasksPage
          ? 'Tasks'
          : subprocess || process || scenario
            ? `${
                subprocess
                  ? `Subprocess ${subprocess.name}`
                  : process
                    ? `Process ${process.name}`
                    : scenario
                      ? `Scenario ${scenario.name}`
                      : ''
              }`
            : 'Overview'}
      </Typography>
      {!loading && (
        <Paper variant="outlined" sx={{ paddingX: 1.5, paddingY: 1 }}>
          <Stack direction="row" justifyContent="flex-start" alignItems="baseline" gap={2} flex={1}>
            <Typography component="div" typography="body1" fontWeight="bold" whiteSpace="nowrap">
              {'On time:'}
              <Typography
                component="span"
                typography="body1"
                fontWeight="bold"
                color={onTime === 1 || onTime === undefined ? undefined : 'error.main'}
                sx={{ marginLeft: 1 }}
              >
                {onTime === undefined ? '—' : formatPercentage(onTime)}
              </Typography>
            </Typography>
            <Divider orientation="vertical" flexItem />
            <Typography component="div" typography="body1" fontWeight="bold">
              Completion:
              <Typography
                component="span"
                typography="body1"
                fontWeight="bold"
                color={completion === 1 || completion === undefined ? undefined : 'error.main'}
                sx={{ marginLeft: 1 }}
              >
                {completion === undefined ? '—' : formatPercentage(completion)}
              </Typography>
            </Typography>
            <Divider orientation="vertical" flexItem />
            <Typography component="div" typography="body1" fontWeight="bold">
              On time deliverable:
              <Typography
                component="span"
                typography="body1"
                fontWeight="bold"
                color={onTimeDeliverable === 1 || onTimeDeliverable === undefined ? undefined : 'error.main'}
                sx={{ marginLeft: 1 }}
              >
                {onTimeDeliverable === undefined ? '—' : formatPercentage(onTimeDeliverable)}
              </Typography>
            </Typography>
          </Stack>
        </Paper>
      )}
      <Box sx={{ marginRight: { xs: 0, md: 'auto' } }} />
    </>
  );
}

export const OverviewTitle = React.memo(OverviewTitleComponent);
