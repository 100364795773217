import { useMemo } from 'react';
import { GridColDef, GridRenderCellParams, GridValidRowModel } from '@mui/x-data-grid-premium';
import { Escape } from '../entities/Escape';
import { GanttTask } from '../entities/Task';
import { useReadEscapeListQuery } from '../services/escapeApi';
import { emptyFilterValueOption } from '../utils/datagrid';

type UseEscapeColDefs = {
  escapeColumn: GridColDef;
  escapeNoteColumn: GridColDef;
};

export function useEscapeColDefs(): UseEscapeColDefs {
  const { data: escapeList } = useReadEscapeListQuery();

  const escapeMap = useMemo(() => {
    const escapeMap = new Map<Escape['id'], Escape>();
    if (escapeList) {
      for (let i = 0; i < escapeList.length; i++) {
        escapeMap.set(escapeList[i].id, escapeList[i]);
      }
    }
    return escapeMap;
  }, [escapeList]);

  return {
    escapeColumn: {
      field: 'escapeTypeId',
      headerName: 'Escape',
      type: 'singleSelect',
      valueOptions: escapeList
        ? [emptyFilterValueOption].concat(
            escapeList.map((escape) => ({
              label: escape.name,
              value: escape.id,
            })),
          )
        : [],
      valueFormatter: (value: Escape['id']) => escapeMap.get(value)?.name,
      valueGetter: (value, row: GanttTask) => row.completedTask?.escapeTypeId ?? -1,
      renderCell: ({ value }: GridRenderCellParams<GridValidRowModel, Escape['id']>) =>
        (value && escapeMap.get(value)?.name) ?? '—',
    },
    escapeNoteColumn: {
      field: 'escapeNote',
      headerName: 'Escape Note',
      type: 'string',
      valueGetter: (value, row: GanttTask) =>
        row.completedTask?.escapeNote && row.completedTask?.escapeNote !== '' ? row.completedTask?.escapeNote : '—',
    },
  };
}
