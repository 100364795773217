import React, { useCallback, useMemo } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { AuthGuard } from '@top-solution/microtecnica-utils';
import Stack from '@mui/material/Stack';
import { useFilteredGanttTasks } from '../../../components/FilteredGanttTasks';
import { Layout } from '../../../components/Layout';
import { BreadcrumbSplitMenu } from '../../../components/Layout/BreadcrumbSplitMenu';
import { Breadcrumb } from '../../../components/Layout/Header';
import { AppLayoutProps } from '../../../components/Layout/Layout';
import { User, UserRoleName } from '../../../entities/User';
import { useScenariosById } from '../../../hooks/useScenariosById';
import { useUsersByUsername } from '../../../hooks/useUsersByUsername';
import { useReadProcessListQuery } from '../../../services/processApi';
import { useReadScenarioListQuery } from '../../../services/scenarioApi';
import { useReadSubprocessListQuery } from '../../../services/subprocessApi';
import { useReadUserListQuery } from '../../../services/userApi';
import { printUserName } from '../../../utils/users';
import { HeaderDateFilters, useOverviewQueryParams } from '../overviewQueryParams';

const PAGE_TITLE = 'Overview';

interface OverviewLayoutProps extends AppLayoutProps {}

function OverviewLayoutComponent(props: OverviewLayoutProps) {
  const { breadcrumbs, ...layoutProps } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [qs] = useSearchParams();
  const overviewQueryParams = useOverviewQueryParams();

  const navigateWithQs = useCallback((to: string) => navigate(`${to}?${qs.toString()}`), [navigate, qs]);

  const params = useParams<{
    processId?: string;
    scenarioName?: string;
    subprocessId: string;
    ownerUserName: string;
  }>();

  const processList = useReadProcessListQuery();
  const scenarioList = useReadScenarioListQuery();
  const subprocessList = useReadSubprocessListQuery();
  const userList = useReadUserListQuery();

  const process = useMemo(
    () =>
      params.processId !== undefined &&
      processList.data &&
      processList.data.find((p) => p.id === Number(params.processId)),
    [params.processId, processList.data],
  );

  const scenario = useMemo(
    () =>
      params.scenarioName !== undefined &&
      scenarioList.data &&
      scenarioList.data.find((p) => p.name === params.scenarioName),
    [params.scenarioName, scenarioList.data],
  );

  const subprocess = useMemo(
    () =>
      params.subprocessId !== undefined &&
      subprocessList.data &&
      subprocessList.data.find((p) => p.id === Number(params.subprocessId)),
    [params.subprocessId, subprocessList.data],
  );

  const owner = useMemo(
    () =>
      params.ownerUserName !== undefined &&
      userList.data &&
      userList.data.find((p) => p.username === params.ownerUserName),
    [params.ownerUserName, userList.data],
  );
  const { filteredGanttTasks } = useFilteredGanttTasks();
  const { usersByUsername } = useUsersByUsername();
  const scenariosById = useScenariosById();

  const userOptions = useMemo(() => {
    const s = new Map<string, User>();

    for (const gantt of filteredGanttTasks) {
      if (params.processId !== undefined && Number(params.processId) !== gantt.task.processId) {
        continue;
      }
      if (
        params.scenarioName !== undefined &&
        gantt.task.scenarioId &&
        params.scenarioName !== scenariosById.get(gantt.task.scenarioId)?.name
      ) {
        continue;
      }
      if (params.subprocessId !== undefined && Number(params.subprocessId) !== gantt.task.subprocessId) {
        continue;
      }
      const owner = usersByUsername.get(gantt.task.owner);
      if (owner && owner.role !== UserRoleName.DELETED) {
        s.set(owner.username, owner);
      }
    }

    return Array.from(s.values());
  }, [filteredGanttTasks, params.processId, params.scenarioName, params.subprocessId, scenariosById, usersByUsername]);

  let layoutBreadcrumbs: Breadcrumb[] = [{ title: PAGE_TITLE, url: '/overview' }];

  if (scenario) {
    layoutBreadcrumbs.push({
      title: 'Scenario',
      renderComponent: () => {
        const matcher = `/scenarios/${params.scenarioName}`;
        return (
          <BreadcrumbSplitMenu
            to={`${location.pathname.substring(
              0,
              location.pathname.lastIndexOf(matcher) + matcher.length,
            )}?${qs.toString()}`}
            options={(scenarioList.data ?? []).map((pr) => ({
              value: pr.id,
              label: `[${pr.process.shortName}] ${pr.name}`,
            }))}
            onOptionClick={(_, value) =>
              navigateWithQs(
                `${location.pathname.substring(0, location.pathname.lastIndexOf(matcher))}${`/scenarios/${value}`}`,
              )
            }
          >
            {scenario.name}
          </BreadcrumbSplitMenu>
        );
      },
    });
  }

  if (process) {
    layoutBreadcrumbs.push({
      title: 'Process',
      renderComponent: () => {
        const matcher = `/processes/${params.processId}`;
        return (
          <BreadcrumbSplitMenu
            to={`${location.pathname.substring(
              0,
              location.pathname.lastIndexOf(matcher) + matcher.length,
            )}?${qs.toString()}`}
            options={(processList.data ?? []).map((pr) => ({ value: pr.id, label: pr.shortName }))}
            onOptionClick={(_, value) =>
              navigateWithQs(
                `${location.pathname.substring(0, location.pathname.lastIndexOf(matcher))}${`/processes/${value}`}`,
              )
            }
          >
            {process.shortName}
          </BreadcrumbSplitMenu>
        );
      },
    });
  }
  if (subprocess) {
    layoutBreadcrumbs.push({
      title: 'Subprocess',
      renderComponent: () => {
        const matcher = `/subprocesses/${params.subprocessId}`;

        return (
          <BreadcrumbSplitMenu
            to={`${location.pathname.substring(
              0,
              location.pathname.lastIndexOf(matcher) + matcher.length,
            )}?${qs.toString()}`}
            options={(subprocessList.data ?? []).map((pr) => ({ value: pr.id, label: pr.name }))}
            onOptionClick={(_, value) =>
              navigateWithQs(
                `${location.pathname.substring(0, location.pathname.lastIndexOf(matcher))}${`/subprocesses/${value}`}`,
              )
            }
          >
            {subprocess.name}
          </BreadcrumbSplitMenu>
        );
      },
    });
  }
  if (owner) {
    layoutBreadcrumbs.push({
      title: 'Owner',
      renderComponent: () => {
        const matcher = `/by-user/${params.ownerUserName}`;

        return (
          <BreadcrumbSplitMenu
            to={`${location.pathname.substring(
              0,
              location.pathname.lastIndexOf(matcher) + matcher.length,
            )}/tasks?${qs.toString()}`}
            options={userOptions.map((user) => ({ value: user.username, label: printUserName(user) }))}
            onOptionClick={(_, value) =>
              navigateWithQs(
                `${location.pathname.substring(0, location.pathname.lastIndexOf(matcher))}${`/by-user/${value}/tasks`}`,
              )
            }
          >
            {printUserName(owner)}
          </BreadcrumbSplitMenu>
        );
      },
    });
  }

  layoutBreadcrumbs = layoutBreadcrumbs.concat(breadcrumbs ?? []);

  return (
    <AuthGuard>
      <Layout
        maxWidth={false}
        {...layoutProps}
        breadcrumbs={layoutBreadcrumbs}
        headerFilters={<HeaderDateFilters {...overviewQueryParams} />}
        error={processList.error ?? scenarioList.error ?? subprocessList.error}
      >
        <Stack direction="column" justifyContent="center" gap={1}>
          {props.children}
        </Stack>
      </Layout>
    </AuthGuard>
  );
}

export const OverviewLayout = React.memo(OverviewLayoutComponent);
