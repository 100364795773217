import { GridColDef } from '@mui/x-data-grid-premium';
import { GanttTask } from '../entities/Task';

export function useDeliverableColDef(): GridColDef {
  return {
    field: 'isDeliverable',
    type: 'boolean',
    headerName: 'Deliverable',
    width: 50,
    valueGetter: (value, row: GanttTask) => row.task.isDeliverable,
  };
}
