import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';

interface CalendarDayProps {
  checked: boolean;
  children: React.ReactNode;
  title?: string;
  opacity?: number;
  onClick?: ButtonProps['onClick'];
  className?: string;
  color?: ButtonProps['color'];
  disabled?: boolean;
}
function UnstyledCalendarDayButton(props: CalendarDayProps) {
  return <Button {...props} variant="contained" disabled={!props.checked || props.disabled} />;
}

function UnstyledCalendarDay(props: CalendarDayProps) {
  return <div className={props.className}>{props.children}</div>;
}

export const StyledCalendarDayButton = styled(UnstyledCalendarDayButton)((props) => ({
  opacity: props.opacity,
}));

export const StyledCalendarDay = styled(UnstyledCalendarDay)(({ theme, ...props }) => ({
  opacity: props.opacity,
  backgroundColor: props.checked ? undefined : 'transparent',
  '&': {
    color: theme.palette.text.secondary,
  },
  '&.Mui-disabled': {
    backgroundColor: 'transparent',
  },
}));

export function CalendarDay(props: CalendarDayProps) {
  if (props.checked) {
    return <StyledCalendarDayButton className="CalendarDay" {...props} />;
  } else {
    return <StyledCalendarDay className="CalendarDay" {...props} />;
  }
}
