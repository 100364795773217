import { useEffect, useMemo, useState } from 'react';
import { DataGridWrapper, ErrorAlert } from '@top-solution/microtecnica-mui';
import { useAuth, useRemoveAppUserMutation } from '@top-solution/microtecnica-utils';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import {
  GridActionsCellItem,
  GridActionsColDef,
  GridColDef,
  GridFilterModel,
  GridPinnedColumnFields,
  GridRenderCellParams,
  GridRowParams,
} from '@mui/x-data-grid-premium';
import { DeleteConfirmDialog } from '../../../components/DeleteConfirmDialog';
import { DeleteIcon, EditIcon, TimetableIcon } from '../../../components/Icons';
import { Layout } from '../../../components/Layout';
import { PAGE_TITLE_OFFSET_HEIGHT, PageTitle } from '../../../components/PageTitle';
import { StyledDataGrid } from '../../../components/StyledDataGrid';
import { User, UserRoleName } from '../../../entities/User';
import { DayTimetable } from '../../../entities/UserTimetable';
import { usePlannerConfig } from '../../../hooks/usePlannerConfig';
import { useUserRoleColDef } from '../../../hooks/useUserRoleColDef';
import { useRemoveUserMutation, useReadUserListQuery } from '../../../services/userApi';
import { stripedGetRowClassName } from '../../../utils/datagrid';
import { formatHour } from '../../../utils/date';
import { sortedDaysOfWeek } from '../Task/TaskEditDialog/ScheduleForm/utils';
import { UserAddButton } from './UserAddButton';
import { UserEditDialog } from './UserEditDialog';
import { UserTimetableDialog } from './UserTimetableDialog';

const title = 'Manage Users';
const breadcrumbs = [{ title }];
const pinnedColumnFields: GridPinnedColumnFields = { right: ['actions'], left: ['username'] };

const emptyValueFormatter = (value: string | undefined) => (value && value.length ? value : '—');

export function UserList(): JSX.Element {
  const [userToDelete, setUserToDelete] = useState<User | null>(null);
  const [userToEdit, setUserToEdit] = useState<User | null>(null);
  const [timetableToEdit, setTimetableToEdit] = useState<User | null>(null);
  const userList = useReadUserListQuery();
  const [remove, removeRqeuest] = useRemoveUserMutation();
  const [removeAppUser, removeAppUserRequest] = useRemoveAppUserMutation();
  const userRoleColumn = useUserRoleColDef();
  const { username } = useAuth();
  const { datagridProps, datagridInitialState } = usePlannerConfig();

  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  });

  async function handleDeleteConfirm() {
    if (userToDelete) {
      if (userToDelete.role !== UserRoleName.GUEST) {
        await removeAppUser({
          appId: import.meta.env.VITE_APP_ID,
          user: {
            username: userToDelete.username,
            roles: [],
          },
        });
        userList.refetch();
      } else {
        await remove(userToDelete).unwrap();
      }
      setUserToDelete(null);
    }
  }

  const rows = useMemo(
    () => (userList.data ?? []).map((user) => ({ ...user, id: `${user.username}___${user.id}` })),
    [userList.data],
  );

  const columns = useMemo<(GridColDef | GridActionsColDef)[]>(() => {
    const cols: GridColDef<User>[] = [
      {
        field: 'username',
        headerName: 'Username',
        width: 150,
      },
      {
        field: 'lastName',
        headerName: 'Lastname',
        width: 150,
        valueFormatter: emptyValueFormatter,
      },
      {
        field: 'firstName',
        headerName: 'Firstname',
        width: 150,
        valueFormatter: emptyValueFormatter,
      },
      {
        field: 'email',
        headerName: 'Email',
        width: 250,
        valueFormatter: emptyValueFormatter,
      },
      {
        ...userRoleColumn,
      },
      ...sortedDaysOfWeek.map<GridColDef>(({ day, index }) => ({
        field: `timetable.${index}`,
        headerName: day,
        filterable: false,
        sortable: false,
        headerAlign: 'center',
        renderCell: ({ row }: GridRenderCellParams<User, DayTimetable>) => {
          if (!row.timetable) {
            return '';
          }
          const workingHours = row.timetable[index];
          if (workingHours) {
            return (
              <Stack direction="row">
                <Box sx={{ width: '100px', textAlign: 'center' }}>
                  {workingHours.startMorning
                    ? `${formatHour(workingHours.startMorning)} - ${formatHour(workingHours.endMorning)}`
                    : '—'}
                </Box>
                <Box sx={{ width: '100px', textAlign: 'center' }}>
                  {workingHours.startAfternoon
                    ? `${formatHour(workingHours.startAfternoon)} - ${formatHour(workingHours.endAfternoon)}`
                    : '—'}
                </Box>
              </Stack>
            );
          }
        },
        width: 220,
      })),
    ];
    return [
      ...cols,
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Actions',
        width: 130,
        renderHeader: () => <UserAddButton onRefetch={userList.refetch} />,
        getActions: ({ row }: GridRowParams<User>) => {
          const actions = [
            <GridActionsCellItem
              icon={<TimetableIcon />}
              key="edit"
              label="Edit timetable"
              onClick={() => setTimetableToEdit(row)}
            />,
            ...(row.role === UserRoleName.GUEST
              ? [
                  <GridActionsCellItem
                    icon={<EditIcon />}
                    key="edit"
                    label="Edit user"
                    onClick={() => setUserToEdit(row)}
                  />,
                ]
              : [
                  <Tooltip key="edit-disabled" title="Non-guest users cannot be edited">
                    <Box>
                      <GridActionsCellItem icon={<EditIcon />} key="edit" label="" disabled />
                    </Box>
                  </Tooltip>,
                ]),
            <GridActionsCellItem
              icon={<DeleteIcon />}
              key="delete"
              label="Delete"
              onClick={() => setUserToDelete(row)}
              disabled={row.username === username}
            />,
          ];

          return actions;
        },
      },
    ];
  }, [userList.refetch, userRoleColumn, username]);

  useEffect(() => {
    if (!userToDelete) {
      removeRqeuest.reset();
      removeAppUserRequest.reset();
    }
  }, [removeAppUserRequest, removeRqeuest, userToDelete]);

  const initialState = datagridInitialState((config) => config.pages?.users?.datagrid);

  return (
    <Layout title={title} breadcrumbs={breadcrumbs} maxWidth={false}>
      <PageTitle title="Manage Users" />
      {userList.error ? <ErrorAlert error={userList.error} sx={{ marginBottom: 1 }} /> : null}
      <DataGridWrapper offsetHeight={PAGE_TITLE_OFFSET_HEIGHT - 36}>
        <StyledDataGrid
          columns={columns}
          pinnedColumns={pinnedColumnFields}
          rows={rows}
          loading={userList.isFetching}
          getRowId={(user) => user.id}
          paginationMode="client"
          disableAggregation
          disableRowGrouping
          filterMode="client"
          filterModel={filterModel}
          onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
          getRowClassName={stripedGetRowClassName}
          initialState={{
            ...initialState,
            sorting: {
              sortModel: [
                {
                  field: 'username',
                  sort: 'asc',
                },
              ],
            },
          }}
          {...datagridProps((datagridConfig) => ({
            pages: { users: { datagrid: datagridConfig } },
          }))}
        />
      </DataGridWrapper>
      {userToEdit && (
        <UserEditDialog
          title={`Edit user ${userToEdit.username}`}
          open={Boolean(userToEdit)}
          initialValues={userToEdit as User}
          onClose={() => {
            // The endpoint is on a different API, using manual refetch
            setUserToEdit(null);
            userList.refetch();
          }}
        />
      )}
      {timetableToEdit && (
        <UserTimetableDialog
          open={Boolean(timetableToEdit)}
          user={timetableToEdit}
          onClose={() => setTimetableToEdit(null)}
          editMode
        />
      )}
      {userToDelete && (
        <DeleteConfirmDialog
          title={`Are you sure to remove the ${userToDelete.role} user ${userToDelete.username}?`}
          confirmButtonText="Remove user"
          open={Boolean(userToDelete)}
          onConfirm={handleDeleteConfirm}
          onClose={() => setUserToDelete(null)}
          error={removeRqeuest.error ?? removeAppUserRequest.error}
          inProgress={removeRqeuest.isLoading ?? removeAppUserRequest.isLoading}
        />
      )}
    </Layout>
  );
}
