import { useMemo } from 'react';
import { User } from '../entities/User';
import { useReadUserListQuery } from '../services/userApi';
import { printUserNameWithUsername } from '../utils/users';

export function useUsersByUsername() {
  const { data } = useReadUserListQuery();

  return useMemo(() => {
    const usersByUsername = new Map<User['username'], User>();
    const usersByName = new Map<string, User>();
    for (const user of data ?? []) {
      usersByUsername.set(user.username, user);
      usersByName.set(printUserNameWithUsername(user), user);
    }
    return { usersByUsername, usersByName };
  }, [data]);
}

export function cropUsername(nameWithUsername: string) {
  return nameWithUsername.split(' (')[0];
}
